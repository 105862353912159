/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'exclamation-lg': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.005 3.1a1 1 0 111.99 0l-.388 6.35a.61.61 0 01-1.214 0zM7 12a1 1 0 112 0 1 1 0 01-2 0"/>',
    },
});
